import React from 'react';

export const Logo = ({ className }) => (
  <svg className={className} viewBox="0 0 338 143">
    <defs>
      <clipPath id="clip-path">
        <rect
          width="338"
          height="143"
          transform="translate(127 160)"
          fill="none"
          stroke="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="-0.028"
        y1="0.32"
        x2="1"
        y2="0.676"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#67b9ff" />
        <stop offset="1" stopColor="#604bff" />
      </linearGradient>
    </defs>
    <g transform="translate(-127 -160)" clipPath="url(#clip-path)">
      <path
        d="M41.654,0a52.8,52.8,0,0,0-13.5,1.625Q6.521,7.325,0,33.323q12.5-16.661,29.158-12.5C35.5,22.411,40.025,27.009,45.039,32.1c8.168,8.291,17.621,17.886,38.269,17.886q33.323,0,41.654-33.323-12.5,16.661-29.158,12.5c-6.338-1.584-10.867-6.182-15.881-11.271C71.755,9.6,62.3,0,41.654,0Z"
        transform="matrix(0.259, 0.966, -0.966, 0.259, 160.913, 168.518)"
        fill="url(#linear-gradient)"
      />
      <path
        d="M41.654,0a52.8,52.8,0,0,0-13.5,1.625Q6.521,7.325,0,33.323q12.5-16.661,29.158-12.5C35.5,22.411,40.025,27.009,45.039,32.1c8.168,8.291,17.621,17.886,38.269,17.886q33.323,0,41.654-33.323-12.5,16.661-29.158,12.5c-6.338-1.584-10.867-6.182-15.881-11.271C71.755,9.6,62.3,0,41.654,0Z"
        transform="translate(149.923 210.232) rotate(30)"
        fill="url(#linear-gradient)"
      />
      <path
        d="M46.558,0a52.8,52.8,0,0,0-13.5,1.625C18.636,5.425,4.332,17.371,0,34.7,8.331,23.591,22.954,18.05,34.062,20.827,40.4,22.411,44.929,27.009,49.943,32.1c8.168,8.291,17.621,17.886,38.269,17.886q33.323,0,41.654-33.323-12.5,16.661-29.158,12.5C94.37,27.573,89.84,22.976,84.826,17.886,76.658,9.6,67.205,0,46.558,0Z"
        transform="translate(115.552 265.466) rotate(-60)"
        fill="url(#linear-gradient)"
      />
      <path
        d="M14.187-22.884h-.143V-51.435H3.636V0H14.044V-12.618l2.709-2.923L27.7,0H39.672L24.381-21.814l14.579-16.4h-11.8Zm33.827-20.5a5.378,5.378,0,0,0,5.418-5.347,5.355,5.355,0,0,0-5.418-5.347A5.355,5.355,0,0,0,42.6-48.726,5.378,5.378,0,0,0,48.013-43.379ZM42.845,0H53.253V-38.211H42.845Zm20.1-47.015v8.8H57.566v7.842h5.382V-10.3c0,7.45,3.493,10.444,12.476,10.444a23.927,23.927,0,0,0,4.955-.428V-7.949a16.844,16.844,0,0,1-2.531.178c-3.065,0-4.491-1.39-4.491-4.313V-30.369h7.058v-7.842H73.356v-8.8Zm76.778,8.8H129.461l-5.81,28.337h-.214l-6.808-28.337h-9.98L99.876-9.874h-.214L93.888-38.211H83.408L93.567,0H104.51l6.879-27.019h.214L118.518,0H129.6Zm9.41-5.168a5.378,5.378,0,0,0,5.418-5.347,5.355,5.355,0,0,0-5.418-5.347,5.355,5.355,0,0,0-5.418,5.347A5.378,5.378,0,0,0,149.137-43.379ZM143.968,0h10.408V-38.211H143.968Zm17.323,0H171.7V-21.921c0-4.955,2.958-8.376,7.628-8.376s7.022,2.852,7.022,7.842V0h10.408V-24.63c0-8.947-4.776-14.293-13.26-14.293-5.881,0-9.909,2.78-11.941,7.521h-.214v-6.808H161.292Zm56.247.606c5.632,0,10.052-2.958,11.905-7.165h.178V0h10.266V-51.435H229.479v19.783h-.214c-1.854-4.242-6.131-7.165-11.656-7.165-9.588,0-15.648,7.521-15.648,19.64C201.962-6.951,207.986.606,217.539.606Zm3.529-31.082c5.168,0,8.483,4.42,8.483,11.371,0,6.986-3.315,11.335-8.483,11.335-5.24,0-8.448-4.313-8.448-11.335C212.62-26.092,215.828-30.476,221.067-30.476Z"
        transform="translate(225 261)"
        fill="#180536"
      />
    </g>
  </svg>
);
