import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const SEO = ({
  description,
  lang,
  meta,
  title,
  previewImage,
  previewImageTwitter,
}) => {
  const { site, previewOpenGraph, previewTwitter } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        previewOpenGraph: file(absolutePath: { regex: "/kitwind-og.png/" }) {
          childImageSharp {
            fixed(width: 2560) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        previewTwitter: file(absolutePath: { regex: "/kitwind-twitter.png/" }) {
          childImageSharp {
            fixed(width: 2560) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const metaTitle = site.siteMetadata.title;

  const metaPreview =
    previewImage || previewOpenGraph.childImageSharp.fixed.src;

  const metaPreviewTwitter =
    previewImageTwitter || previewTwitter.childImageSharp.fixed.src;

  const fullMetaPreview = site.siteMetadata.siteUrl + metaPreview;

  const fullMetaPreviewTwitter = site.siteMetadata.siteUrl + metaPreviewTwitter;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: fullMetaPreview,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: fullMetaPreviewTwitter,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};
